import { AfterViewInit, ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { BaseComponent } from './base-component';
import { ComponentConfig } from '@interfaces/config';
import { SPS_COLORS } from '@core/config';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class BaseCmsComponent<T extends ComponentConfig> extends BaseComponent implements OnInit, AfterViewInit {
  protected scroller = inject(ViewportScroller);
  protected route = inject(ActivatedRoute);
  protected _config: T = {} as T;
  protected readonly darkColors = [SPS_COLORS.BLUE, SPS_COLORS.BLUE_DARK];

  @Input() set config(config: Partial<T>) {
    config ||= {};
    this._config = { ...this._config, ...config } as T;
  }

  ngOnInit() {
    this.setBackgroundStyle();
    this.setMargins();
  }

  ngAfterViewInit() {
    this.setAnchorId();

    if (this.isSelectedElement) {
      // Give previous components some time to render ...
      setTimeout(() => {
        this.scroller.scrollToAnchor(this.config.nodeId);
      }, 300);
    }
  }

  get config(): T {
    return this._config ?? ({} as T);
  }

  get isSelectedElement(): boolean {
    if (!this.route.snapshot.fragment) {
      return false;
    }

    return this.route.snapshot.fragment === this.config?.anchor;
  }

  public hasDarkBg(color: string = this.config?.bgColor): boolean {
    if (!color) {
      return false;
    }

    return this.darkColors.includes(color.toLowerCase());
  }

  private setAnchorId(): boolean {
    if (!this.config?.anchor || !this.config.nodeId) {
      return false;
    }

    const id = this.config.anchor || this.config.nodeId;
    this.el.nativeElement.id = id;
    this.el.nativeElement.setAttribute('data-anchor', id);

    return true;
  }

  private setBackgroundStyle(): void {
    if (!this.config?.bgColor) {
      return;
    }

    this.el.nativeElement.classList.add('bg-color');
    this.setCustomProperty('--bg-color', this.config.bgColor);

    this.el.nativeElement.classList.toggle('dark-bg', this.hasDarkBg());
    this.el.nativeElement.classList.toggle('color-white', this.hasDarkBg());
  }

  private setMargins(): void {
    if (!this.config?.margins?.length) {
      return;
    }

    this.config.margins.forEach((margin, i) => {
      if (!margin) {
        return;
      }

      const property = i ? '--margin-bottom' : '--margin-top';
      this.setCustomProperty(property, margin.toString());
    });
  }
}
